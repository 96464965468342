import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import i18n from "./i18n";
import scrollDirectives from './directives/scrollDirectives';
import { createToaster } from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3"
import axios from 'axios'
import { createHead } from "@vueuse/head"
import VueRecaptcha from "vue3-recaptcha-v2";


const axiosInstance = axios.create({
  baseURL: 'https://new.dasm.kz/api/', 
  params: {
    lang: store.getters.getLang
  }
})

const head = createHead()
axios.defaults.baseURL = 'https://new.dasm.kz/api/';
axios.defaults.params = {
  lang: store.getters.getLang
};


const app = createApp(App)
    .use(VueAwesomePaginate)
    .use(store)
    .use(i18n)
    .use(VueRecaptcha, {
        siteKey: "6LdpdlEpAAAAALS0Jby2gp14TgDZTNqxr0t4mANH",
        alterDomain: true
    })
    .use(head)
    .use(vClickOutside)
    .directive('scroll', scrollDirectives)
    .use(router)
    app.config.globalProperties.$axios = { ...axiosInstance }
    app.config.globalProperties.$cdn = 'https://new.dasm.kz/api/'
    app.config.globalProperties.$toaster = createToaster({ position: "top-right", duration: 1900 })
    app.mount('#app')
