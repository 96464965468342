<template>
    <div class="news__block2__content__box" @click="$router.push('/news/' + newsValue.slug)" :class="{ notLine: indexProps + 1 >= lengthProps}">
        <div class="news__block2__content__box__left">
            <div class="news__block2__content__box__left-in">
                <p class="news__block2__content__box__left-p1">{{ newsValue.date }}</p>
                <p class="news__block2__content__box__left-p2" v-html="formattedTitle"></p>
                <p class="news__block2__content__box__left-p3" v-html="newsValue.content"></p>
                <p class="news__block2__content__box__left-p4 desctop">{{ $t('knowMore') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
    components: { VLazyImage },
    props: ['newsValue', 'indexProps', 'lengthProps'],
    computed: {
        formattedTitle() {
            // const words = this.newsValue.title.split(" ");

            // if (words.length > 1) {
            //     return words[0] + "<br>" + words.slice(1).join(" ");
            // }

            return this.newsValue.title;
        }
    },

    mounted() {
        const paragraphs = document.querySelectorAll('.news__block2__content__box__left-p3');

        let maxHeight = 0;

        var pageWidth = document.documentElement.scrollWidth;
        var pageHeight = window.innerHeight;

        if (pageWidth <= 820 & pageHeight >= 1180) {
            maxHeight = 63;
        }
        else if (pageWidth > 768) {
           maxHeight = 69;
        }
        
        else if (pageWidth >= 390) {
            maxHeight = 96;
        }
        
        else if (pageWidth <= 280) {
            maxHeight = 97;
        }

        else {
            maxHeight = 97;
        }

        paragraphs.forEach(paragraph => {
            const paragraphHeight = paragraph.offsetHeight;
            const paragraphWidth = paragraph.offsetWidth;
            if (paragraphHeight > maxHeight) {
                paragraph.style.height = maxHeight + 'px';
                paragraph.style.overflow = 'hidden';

                const text = paragraph.textContent;
                let slicedText;
                
                if (pageWidth <= 820 & pageHeight >= 1180) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 90)) + '...';
                }
                else if (pageWidth > 768) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 151)) + '...';
                }
                else if (pageWidth >= 390) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 185)) + '...';
                }
                else if (pageWidth >= 280) {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 120)) + '...';
                }
                else {
                    slicedText = text.slice(0, text.lastIndexOf(' ', 160)) + '...';
                }
                paragraph.textContent = slicedText;
            }
            else {

            }
        });
    }
}
</script>

<style lang="scss" scoped>
.notLine {
    border-bottom: none !important;
    @media screen and (max-width: 768px) {
        border-bottom: 0.5px solid #dfdfdf !important;
    }
}
.news__block2__content__box {
    height: 50%;
    max-height: fit-content;
    padding-top: 40px;
    padding-left: 15%;
    padding-right: 19%;
    border-bottom: 0.5px solid #dfdfdf;
    display: flex;
    overflow: hidden;
    @media screen and (max-width: 1152px) {
        padding-top: 5px;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        height: 50%;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-bottom: 8%;
    }
    @media screen and (max-width: 280px) {
        flex-direction: column-reverse;
        height: 50%;
        padding-left: 6.5%;
        padding-right: 6.5%;
        padding-bottom: 0%;
    }

    &__left {
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        &-p1 {
            color: #BDBDBDCC;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
        }

        &-p2 {
            margin-top: 20px;
            text-transform: uppercase;
            font-family: $CeraBlack;
            font-weight: 900;
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            @media screen and (max-width: 1024px) {
                padding-right: 10px;
                margin-top: 5px;
            }
        }

        &-p3 {
            margin: 16px 0 23px 0;
            font-weight: 400;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                margin: 25px 0 25px 0;
            }
            
            @media screen and (max-width: 280px) {
                margin: 15px 0 5px 0;
            }
        }

        &-p4 {
            cursor: pointer;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 700;
        }
    }
}

.v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
}

.v-lazy-image-loaded {
    filter: blur(0);
}</style>