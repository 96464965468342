<template>
    <div class="style90">
        <img src="@/assets/images/ugol2.png" />
    </div>
    <div class="about">
        <div class="about__block1">
            <p v-html="aboutApi?.title"></p>
        </div>

        <div class="about__block2" v-if="aboutApi?.countries">
            <div class=about__block2__box1 v-html="aboutApi?.countries[0]?.title">
            </div>

            <div class="about__block2__box2" v-for="(item, index) in aboutApi?.countries.splice(1)" :key="index"
                :class="{ about__block2__box3: index % 2 !== 0 }">
                <p class="about__block2__box2-p1">/0{{ ++index }}</p>
                <p class="about__block2__box2-p2" v-html="item.title" :class="{ 'secThrird': index == 2 || index == 3, 'styleForBlock2': index == 1 }"></p>
            </div>

        </div>


        <div class="about__block5">
            <p class="about__block5-title">{{ $t('agenV') }}</p>

            <Swiper class="about__block5__swiper" 
            v-if="aboutApi"
            :lazy="true" :loop="true" :centeredSlides="true" :speed="1200"
                :mousewheel="true"
                :breakpoints="{
                    1000: {
                        slidesPerView: 3,
                        spaceBetween: 100
                    },
                    100: {
                        slidesPerView: 1.5,
                        spaceBetween: 30
                    }
                                    }" :autoplay="{
                        delay: 2000,
                        disableOnInteraction: true,
                    }">
                <SwiperSlide v-for="(item, index) in homeApi?.show_cases" :key="'image' + index"
                    class="about__block5__swiper__item">
                    <img :src="item.logo" />
                </SwiperSlide>
            </Swiper>


            <router-link to="/showcase" class="about__block5-button">{{ $t('vacancies.more') }}</router-link>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { A11y, Autoplay, Mousewheel } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Mousewheel])

import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { mapMutations } from 'vuex'

export default {
    components: { Swiper, SwiperSlide },
    data() {
        return {
            homeApi: "",
            isAnimating: false,
            siteData: null,
            aboutApi: "",
            titleMeta: "",
            descMeta: "",
            keywordsMeta: "",
            slider: [],
            block4: [
                {
                    title: "1994",
                    desc: "ourHistoryH1"
                },
                {
                    title: "2001",
                    desc: "ourHistoryH2"
                },
                {
                    title: "2006",
                    desc: "ourHistoryH3"
                },
                {
                    title: "ИКРК",
                    desc: "ourHistoryH4"
                },
                {
                    title: "TNS",
                    desc: "ourHistoryH5"
                }
            ]
        }
    },


    async created() {
        this.SET_LOADING(true)
        await this.$axios.get(`/V1/page/countries`)
            .then((response) => {
                this.aboutApi = response.data;
                this.slider = response.data.about_images;
                this.titleMeta = response.data.title;
                this.descMeta = response.data.meta_description;
                this.keywordsMeta = response.data.meta_keywords;
                this.SET_LOADING(false);
                setTimeout(() => {
                    this.changeTextHeight();
                }, 1);
                
            })
            .catch((e) => {
                this.SET_LOADING(false)
            });

        await this.$axios.get(`/V1/page/home`)
            .then((response) => {
                this.homeApi = response.data;
                this.SET_LOADING(false)
            })
            .catch((e) => {
                this.SET_LOADING(false)
            });

        await this.initializeSiteData();
    },

    methods: {
        ...mapMutations(["SET_LOADING"]),

        initializeSiteData() {
            this.siteData = reactive({
                title: this.titleMeta,
                description: this.descMeta,
                keywords: this.keywordsMeta
            });

            useHead({
                meta: [
                    {
                        name: computed(() => 'title'),
                        content: computed(() => this.siteData.title),
                    },
                    {
                        name: computed(() => 'description'),
                        content: computed(() => this.siteData.description),
                    },
                    {
                        name: computed(() => 'keywords'),
                        content: computed(() => this.siteData.keywords),
                    },
                ]
            });
        },

        changeTextHeight() {
            const paragraphs = document.querySelectorAll('.secThrird');

            let maxHeight = 0;

            paragraphs.forEach((paragraph) => {
                const paragraphHeight = paragraph.clientHeight; 
                if (paragraphHeight > maxHeight) {
                    maxHeight = paragraphHeight;
                }
            });

            paragraphs.forEach((paragraph) => {
                paragraph.style.height = maxHeight + 'px';
            });
        },


        sliderPrev() {

            if (this.isAnimating) {
                return;
            }

            this.isAnimating = true;

            const lastItem = this.slider.pop();
            this.slider.unshift(lastItem);

            const element = document.querySelector('.about__block3__left');
            const element2 = document.querySelector('.about__block3__right__top');
            const element3 = document.querySelector('.about__block3__right__bot');
            element.classList.add('new-class');
            element2.classList.add('new-class');
            element3.classList.add('new-class');

            setTimeout(() => {
                element.classList.remove('new-class');
                element2.classList.remove('new-class');
                element3.classList.remove('new-class');

                this.isAnimating = false;
            }, 2000);
        },
        sliderNext() {
            if (this.isAnimating) {
                return;
            }

            this.isAnimating = true;

            const firstItem = this.slider.shift();
            this.slider.push(firstItem);

            const element = document.querySelector('.about__block3__left');
            const element2 = document.querySelector('.about__block3__right__top');
            const element3 = document.querySelector('.about__block3__right__bot');
            element.classList.add('new-class');
            element2.classList.add('new-class');
            element3.classList.add('new-class');

            setTimeout(() => {
                element.classList.remove('new-class');
                element2.classList.remove('new-class');
                element3.classList.remove('new-class');

                this.isAnimating = false;
            }, 2000);
        }
    }
}
</script>
<style lang="scss" scoped>
.styleForBlock2 {
    margin-bottom: 70px;
    @media screen and (max-width: 768px) {
        margin-bottom: 0px;
    }
}
.style90 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        opacity: 0.8;
    }

    @media screen and (max-width: 1440px) {
        img {
            width: 75%;
        }
    }
    @media screen and (max-width: 1024px) {
        img {
            width: 45%;
        }
    }
    @media screen and (max-width: 768px) {
        img {
            width: 45%;
            opacity: 0.85;
        }
    }
}

.new-class {
    animation: zoomIN 2.2s infinite ease;
    transition: all 2.2s ease;
}

@keyframes zoomIN {
    0% {
        opacity: 0.7;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.about {
    margin-bottom: 120px;
    @media screen and (max-width: 768px) {
        margin-bottom: 250px;
        position: relative;
        z-index: 2;
    }
    @media screen and (max-width: 454px) {
        margin-bottom: 200px;
        position: relative;
        z-index: 2;
    }
    &__block1 {
        height: fit-content;
        min-height: 28.58vw;
        position: relative;
        z-index: 2;
        padding-top:  13.188rem;
        margin: auto;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 900;
        font-size: min(max(30px, calc(1.875rem + ((1vw - 3.2px) * 1.7857))), 50px);
        text-align: center;
        line-height: 50px;
        overflow: inherit;
        p {
            overflow: inherit;
        }
        @media screen and (max-width: 1240px) {
            padding-top: 15.38vw;
        }
        @media screen and (max-width: 768px) {
            height: 300px;
            min-height: fit-content;
            padding-top: 145px;
            line-height: 35px;
            overflow: inherit;
        }
        p {
            font-family: $CeraBlack;
            font-weight: 900;
        }
    }

    &__block2 {
        border-bottom: 0.5px solid #BDBDBD;
        display: flex;
        flex-wrap: wrap;
        &__box1 {
            padding: 60px 4.3% 80px 11.31%;
            border: 0.5px solid #e6e5e5;
            height: auto;
            min-height: 33.66vw;
            background: rgba(242, 242, 242, 0.5);
            width: 50%;
            font-size: min(max(19px, calc(1.1875rem + ((1vw - 3.2px) * 0.4464))), 24px);
            span {
             font-weight: 900 !important;
            }
            @media screen and (max-width: 768px) {
                border: 0.5px solid transparent;
                width: 100%;
                height: fit-content;
                min-height: 210px;
                padding: 7% 7% 12% 7%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
            }
        }
        &__box3 {
            padding: 60px 4.3% 80px 11.31% !important;
            // padding: 60px 4.3% 80px 11.31% !important;
        }
        &__box2 {
            border-left: 1px solid transparent !important;
            border: 0.5px solid #e6e5e5;
            height: auto;
            min-height: 33.66vw;
            padding: 60px 5% 80px 3%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            @media screen and (max-width: 1024px) {
                gap: 15px;
            }
            &-p1 {
                overflow: inherit;
                color: #E92D34;
                font-weight: 700;
                font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            }
            &-p2 {
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px);
                height: fit-content;
                overflow: hidden;
                width: 77%;
                @media screen and (max-width: 768px) {
                    height: fit-content;
                    // min-height: 150px;
                }
            }
            span {
                font-weight: 900;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                height: fit-content;
                min-height: 210px;
                padding: 9% 10% 11% 10%  !important;
                display: flex;
                gap: 35px;
                align-items: start;
                justify-content: flex-start;
            }
        }
    }

    &__block3 {
        margin: 115px 0 130px 0;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            margin: 50px 0 180px 0;
            gap: 2px;
        }

        &__left {
            transform: scale(1);
            height: 33.66vw;
            background-size: cover;
            background-repeat: no-repeat;
            width: 68.84%;

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media screen and (max-width: 425px) {
                width: 100% !important;
                height: 25vh !important;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                height: 50vh;
            }
        }

        &__right {
            width: 30.76%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                width: 100%;
                flex-direction: initial;
            }

            &__top {
                height: 49.5%;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                z-index: 3;
                overflow: hidden;
                cursor: pointer;

                @media screen and (max-width: 425px) {
                    height: 10vh !important;
                    width: 50% !important;
                }

                @media screen and (max-width: 768px) {
                    height: 20vh;
                    width: 50%;
                }

                &:hover {
                    &-img {
                        transform: scale(1.1);
                    }

                    &::after {
                        transform: scale(1.1);
                        background: rgba(126, 31, 34, 0.6);
                    }
                }

                &::after {
                    transition: all 0.5s ease;
                    content: url('@/assets/icons/arrow90deg1000.png');
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    position: absolute;
                    background: rgba(235, 33, 39, 0.6);

                    @media screen and (max-width: 768px) {
                        content: url('@/assets/icons/arrow90deg1000Mobile.png');
                    }
                }

                &-img {
                    transition: all 0.5s ease;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__bot {
                height: 49.5%;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                z-index: 3;
                overflow: hidden;
                cursor: pointer;

                @media screen and (max-width: 425px) {
                    height: 10vh !important;
                    width: 50% !important;
                }

                @media screen and (max-width: 768px) {
                    height: 20vh;
                    width: 50%;
                }

                &:hover {
                    &-img {
                        transform: scale(1.1);
                    }

                    &::after {
                        transform: scale(1.1);
                        background: rgba(126, 31, 34, 0.6);
                    }
                }

                &::after {
                    transition: all 0.5s ease;
                    content: url('@/assets/icons/arrow90deg1000Rotate.png');
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    position: absolute;
                    background: rgba(235, 33, 39, 0.6);

                    @media screen and (max-width: 768px) {
                        content: url('@/assets/icons/arrow90deg1000RotateMobile.png');
                    }
                }

                &-img {
                    transition: all 0.5s ease;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }
        }
    }

    &__block4 {
        &-title {
            font-weight: 700;
            font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
            margin-left: 11% !important;

            @media screen and (max-width: 768px) {
                margin-left: 5% !important;
            }
        }

        &__content {
            margin: 115px 0 130px 0;
            display: flex;
            justify-content: space-between;
            border: 0.5px solid rgba(227, 227, 227, 0.8);

            @media screen and (max-width: 425px) {
                flex-direction: column;
                margin: 42px 0 140px 0 !important;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
                margin: 42px 0 230px 0;
            }

            &__left {
                width: 50%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                &__box {
                    margin-left: 25% !important;
                    padding-top: 42px;
                    display: flex;
                    flex-direction: column;
                    gap: 11px;
                    margin: auto;

                    @media screen and (max-width: 768px) {
                        margin-left: 15% !important;
                    }

                    p {
                        overflow-x: initial !important;
                    }

                    &-p1 {
                        font-weight: 700;
                        font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 1.3393))), 50px);
                        color: #E92D34;
                    }

                    &-p2 {
                        padding-bottom: 42px;
                        font-weight: 400;
                        font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.2679))), 18px);
                    }

                    &-line {
                        height: 0.5px;
                        position: relative;
                        width: 120vw;
                        left: -40%;
                        background: rgba(227, 227, 227, 0.8);
                    }
                }
            }

            &__right {
                width: 50%;
                background: white;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                display: flex;
                align-items: flex-end;
                position: relative;
                transition: all 0.5s ease;

                @media screen and (max-width: 425px) {
                    width: 100% !important;
                    height: 50vh !important;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                    height: 100vh;
                }

                &:hover {
                    &::before {
                        background: transparent;
                    }
                }

                &:before {
                    transition: all 0.5s ease;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.1);
                }

                &-box {
                    width: 83.33%;
                    display: flex;
                    font-weight: 700;
                    padding-bottom: 78px;
                    font-size: 30px;
                    margin-right: auto;
                    margin-left: auto;
                    align-items: center;
                    justify-content: space-between;
                    color: #FFFFFF;

                }
            }
        }
    }

    &__block5 {
        margin-top: 100px;
        border-bottom: 0.5px solid #BDBDBD;
        padding-bottom: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 35px;
        @media screen and (max-width: 768px)  {
            margin-top: 36px;
            gap: 0px;
            border-bottom: 0.5px solid transparent;
            padding-bottom: 0px;
        }

        &-title {
            font-family: $CeraBold;
            text-transform: uppercase;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.25))), 32px);
            font-weight: 700;
        }

        &__swiper {
            width: 75%;
            @media screen and (max-width: 456px) {
                overflow: inherit;
                padding: 15px 0;
            }

            .swiper-slide-active {
                transform: scale(1);
                opacity: 1;
            }

            &__item {
                transition: all 0.5s ease;
                opacity: 0.2;
                transform: scale(0.7);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            
                @media screen and (max-width: 456px) {
                    height: 120px;
                }
            }
        }

        &-button {
            text-transform: uppercase;
            cursor: pointer;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.2679))), 15px);
            font-weight: 700;
            padding: 15px 44px;
            background: red;
            color: white;
            outline: none;
            border: none;
            @media screen and (max-width: 768px)  {
               padding: 12px 35px;
            }
        }
    }
}</style>