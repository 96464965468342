<template>
    <router-link :to="'/news/' + newsMiniValue.slug" class="card" :style="{ backgroundImage: 'url(\'' + newsMiniValue.image + '\')' }">
       <div class="card__content">
            <p v-html="newsMiniValue.title"></p>
            <img src="@/assets/icons/arrow90deg.png"/>
       </div>
    </router-link>
</template>
<script>
export default {
    props: ['newsMiniValue'],

    computed: {
        formattedTitle() {
            const words = this.newsMiniValue.title.split(" ");

            if (words.length > 1) {
                return words[0] + "<br>" + words.slice(1).join(" ");
            }

            return this.newsValue?.title;
        }
    }
}
</script>
<style lang="scss" scoped>
.card {
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    background-position: center;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
    @media screen and (max-width: 768px) {
        background-size: cover;
    }
    &:hover {
        // background-size: 105% 105%;
        background-size: cover;
        @media screen and (max-width: 768px) {
            background-size: cover;
        }
        &::before {
            background: transparent;
        }
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
        background: rgba(0, 0, 0, 0.2);
    }
    &__content {
        z-index: 1;
        margin: 0 9.8% 9.8% 22.5%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        color: white;
        font-weight: 900;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
        width: 100%;
        @media screen and (max-width: 768px) {
            margin: 0 10% 10% 10%;
        }
        img {
            @media screen and (max-width: 768px) {
                transform: scale(0.7);
            }
        }
        p {
            position: relative;
            top: 8px;
            font-family: $CeraBlack;
        }
    }
}
</style>