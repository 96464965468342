<template>
    <router-link :to="'/our-works/' + workValue?.slug" class="card">
       <div class="card__content">
            <p v-html="workValue.title"></p>
            <img src="@/assets/icons/arrow90deg.png"/>
       </div>
       <img :src="workValue?.banner" :alt="workValue?.banner" class="card-background"/>
    </router-link>
</template>
<script>
export default {
    props: ['workValue']
}
</script>
<style lang="scss" scoped>
.card {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    // background-size: 100% 100%;
    background-position: center;
    display: flex;
    align-items: flex-end;
    position: relative;
    transition: all 0.5s ease;
    @media screen and (max-width: 768px) {
        background-size: cover;
    }
    &:hover {
        background-size: cover;
        &::before {
            background: transparent;
        }
        &::after {
            opacity: 0.8;
        }
        p {
            text-shadow: 4px 2px 6px rgba(0,0,0,1);
        }
    }
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
        background: rgba(0, 0, 0, 0.2);
    }
    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        bottom: 0;
        opacity: 1;
        transition: all 0.5s ease;
        background: linear-gradient(0deg,rgb(0, 0, 0),transparent 40%);
    }
    &-background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 0.5s ease;
    }
    &__content {
        z-index: 1;
        margin: 0 11.29%  11.29% 21.12%;
        display: flex;
        align-items: flex-end;
        gap: 50px;
        justify-content: space-between;
        color: white;
        font-weight: 900;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
        width: 100%;
        @media screen and (max-width: 820px) and (min-height: 1180px) {
            gap: 30px;
            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
        @media screen and (max-width: 1550px) {
            font-size: 22px;
            margin: 0 10% 10% 10%;
        }
        @media screen and (max-width: 1280px) {
            font-size: 20px;
            margin: 0 10% 10% 10%;
        }
        @media screen and (max-width: 960px) {
            font-size: 18px;
            margin: 0 9% 9% 9%;
        }
        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin: 0 8.5% 8.5% 8.5%;
        }
        @media screen and (max-width: 768px) {
            font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.5357))), 30px);
            gap: 0px;
            margin: 0 10% 10% 10%;
            width: -webkit-fill-available;
        }
        p {
          text-shadow: 4px 2px 6px rgba(0,0,0,0.5);
          transition: all 0.5s ease;
        }
    }
}
</style>