<template>
  <div class="footer container1">
    <div class="footer__top"> 
      <div  class="footer__top__left">
        <p class="footer__top__left-p1" v-html="social.title"></p>
        <div class="footer__top__left-p2" @click="openCoop(true)">
            <img src="@/assets/icons/RightArrow.png"/>
            <p>{{ social.text }}</p>
        </div>
      </div>
      <div  class="footer__top__right">
        <a :href="item.link" target="_blank" class="footer__top__right-box" v-for="(item, index) in svi" :key="index" :class="'footer__top__right-box' + ++index">
          <img :src="item.image"/>
        </a>
      </div>
    </div>
    
    <div class="footer__bot">
        <div class="footer__bot__top desctop">

            <template v-for="(item,index) in menu" :key="index + 'footerMenu'">

              <router-link :to="'/' + item.slug" v-if="item.slug !== 'contacts' && item.slug !== 'https://career.dasm.kz'">
                <p>{{ item.title }}</p>
                <div></div>
              </router-link>

              <span v-if="item.slug === 'https://career.dasm.kz'" class="header__mid-a">
                <a :href="item.slug">{{ item.title }}</a>
                <div></div>
              </span>
            </template>
        </div>
        <p class="footer__bot__bot">© 2022 DASM</p>
    </div>

    <!-- <img src="@/assets/icons/RedLine.png" class="footer-redline"/> -->
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      social: "",
      svi: "",
      menu: ""
    }
  },
  methods: {
      ...mapMutations(["SET_LOADING"]),
      openCoop(value) {
        this.$store.commit('SET_MODAL_COOP', value);
      }
  },

  async created() { 
    this.SET_LOADING(true)

    try {
      const [menuResponse, socialResponse] = await Promise.all([
        this.$axios.get(`/V1/menu`),
        this.$axios.get(`/V1/social`),
      ]);

      this.menu = menuResponse.data.menu;
      this.social = socialResponse.data.cooperate;
      this.svi = socialResponse.data.cooperate.social.reverse();

      this.SET_LOADING(false);
    }
    catch (error) {
      // Handle errors here
      console.error(error);
      this.SET_LOADING(false);
    }

  }
  
}
</script>
<style lang="scss" scoped>
.container1 {
  // margin: auto;
  // width: 66.5%;
  margin-left: 19.70%;
  margin-right: 12.5%;
  @media screen and (max-width: 1280px) {
    margin-left: 16.70%;
    margin-right: 9.5%;
  }
  @media screen and (max-width: 768px) {
    margin: auto;
    width: 90% !important;
  }
}
.footer {
  overflow-x: initial;
  overflow-y: clip;
  padding-bottom: 37px;
  position: relative;
  @media screen and (max-width: 768px) {
    overflow-y: inherit;
  }
  &-redline{
    z-index: 1;
    position: absolute;
    bottom: -1%;
    right: -11%;
    @media screen and (max-width: 820px) and (min-height: 1180px) {
      width: 60%;
      right: -16%;
      top: 42%;
    }
    @media screen and (max-width: 1024px) {
      width: 60%;
      right: -16%;
    }
    @media screen and (max-width: 768px) {
      bottom: auto;
      top: -215px;
      right: -150px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 454px) {
      top: -160px;
    }
  }
  &__top {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: 768px)  {
      flex-direction: column;
      gap: 60px;
      align-items: start;
    }
    &__left {
      display: flex;
      flex-direction: column;
      gap: 90px;
      width: 71.19%;
      @media screen and (max-width: 768px)  {
        width: 100%;
        gap: 30px;
      }
      &-p1 {
        font-weight: 700;
        font-size: min(max(35px, calc(2.1875rem + ((1vw - 3.2px) * 5.8036))), 100px);
        line-height: 80%;
        padding-bottom: 20px;
        overflow: inherit;
        font-family: $CeraBold;
      }
      &-p2 {
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 700;
        font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.25))), 32px);
        p {
          font-family: $CeraBold;
        }
        img {
          @media screen and (max-width: 768px)  {
            width: 13px;
            height: 13px;
            object-fit: contain;
          }
        }
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      gap: 38px;
      margin-bottom: 25px;
      @media screen and (max-width: 768px) {
        flex-direction: initial;
        margin-bottom: 0px;
        gap: 0;
        width: 100%;
        justify-content: space-evenly;
      }
      &-box {
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        border-radius: 50%;
        transition: all 0.5s ease;
        img {
          transition: all 0.5s ease;
          filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(7500%) hue-rotate(36deg) brightness(106%) contrast(107%);
        }
        &:hover {
          background: #0a66c2;
          border: 1px solid transparent !important;
          transform: scale(1.25);
          img {
            filter: none;
          }
        }
      }
    &-box2 {
      &:hover {
        background: #E4405F !important;
        border: 1px solid transparent !important;
        transform: scale(1.25);
      }
    }
    &-box3 {
      &:hover {
        background: #3b5998 !important;
        border: 1px solid transparent !important;
        transform: scale(1.25);
      }
    }
    }
  }
  &__bot {
    position: relative;
    z-index: 2;
    padding-top: 217px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: 31px;
    @media screen and (max-width: 768px) {
      padding-top: 35px;
      width: 100%;
    }
    &__top {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 38px;
      row-gap: 5px;
      font-weight: 400;
      font-size: 12px;
      color: #151515;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 22px;
        justify-content: center;
        text-align: center;
      }
      div {
        width: 1%;
        margin: auto;
        height: 2px;
        transition: all 0.5s ease;
        background: transparent;
      }
      a, .header__mid-a {
        &:hover {
          div {
            width: 100%;
            background: red;
          }
        }
        p {
          color: black;
        }
      }
    }
    &__bot {
      font-weight: 700;
      font-size: 12px;
      opacity: 0.2;
      color: #151515;
    }
  }
}

a {
  color: black;
}
</style>